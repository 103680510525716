import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import logoBg from "../../assets/images/levelup-bg.png";
import logoIcon from "../../assets/images/levelup-icon.png";

class NavBar extends Component {
  handleClick = () => {
    const nav = document.getElementsByClassName("navbar-collapse.show");
    const btn = document.getElementById("navButton");
    window.screen.width < 576 && (nav && btn.click());
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid nav-top">
          <Link
            className="logo"
            to="/"
            alt="Level Up Developers"
            title="Level Up Developers"
          >
            <img src={logoBg} alt="Level Up Developers" className="logo" />
            <img
              src={logoIcon}
              alt="Level Up Developers"
              className="logo logo-icon"
            />
          </Link>
        </div>
        <nav className="navbar navbar-expand-sm navbar-dark bg-custom">
          <button
            className="navbar-toggler btn"
            id="navButton"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMenu"
            aria-controls="navbarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler">&#x2630;</span>
          </button>
          <div className="collapse navbar-collapse ml-auto" id="navbarMenu">
            <NavLink
              className="nav-item nav-link"
              to="/home"
              onClick={this.handleClick}
            >
              Home
            </NavLink>
            <NavLink
              className="nav-item nav-link"
              to="/projects"
              onClick={this.handleClick}
            >
              Projects
            </NavLink>
            <NavLink
              className="nav-item nav-link"
              to="/skills"
              onClick={this.handleClick}
            >
              Skills
            </NavLink>
            <NavLink
              className="nav-item nav-link"
              to="/contact"
              onClick={this.handleClick}
            >
              Contact
            </NavLink>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default NavBar;
