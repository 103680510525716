import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
// Components
import NavBar from "./components/elements/navBar";
import ScrollToTop from "./components/common/srcollFix";
import Footer from "./components/elements/footer";
import Home from "./components/Home";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import NotFound from "./components/404";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { apiResponse: "" };
  }

  render() {
    return (
      <React.Fragment>
        <p className="App-intro">{this.state.apiResponse}</p>
        <NavBar />
        <main className="container-fluid">
          <ScrollToTop>
            <Switch>
              <Route path="/home" component={Home} />
              <Route path="/projects" component={Projects} />
              <Route path="/skills" component={Skills} />
              <Route path="/contact" component={Contact} />
              <Route path="/404" component={NotFound} />
              <Redirect from="/" exact to="/home" />
              <Redirect to="/404" />
            </Switch>
          </ScrollToTop>
        </main>
        <Footer />
      </React.Fragment>
    );
  }
}

export default App;
