import React from "react";

const formInput = ({
  name,
  value,
  onChange,
  placeholder,
  type = "text",
  inline = "",
  hidden = ""
}) => {
  return (
    <div className={"form-group " + inline}>
      <input
        name={name}
        type={type}
        className="form-control"
        id={name}
        placeholder={placeholder}
        hidden={hidden}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default formInput;
