import React from "react";

const OuterText = ({ position, text, size = "h2" }) => {
  return size === "h2" ? (
    <h2 className={position}>{text}</h2>
  ) : (
    <h3 className={position}>{text}</h3>
  );
};

export default OuterText;
