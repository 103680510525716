import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/levelup.png";

const Footer = () => {
  return (
    <footer>
      <Link
        className="logo"
        to="/"
        alt="Level Up Developers"
        title="Level Up Developers"
      >
        <img src={logo} alt="Level Up Developers" className="logo" />
      </Link>
      <div className="container-fluid">
        <ul className="links">
          <li className="link">
            <Link className="nav-link" to="/">
              Home
            </Link>
          </li>
          <li className="link">
            <Link className="nav-link" to="/projects">
              Projects
            </Link>
          </li>
          <li className="link">
            <Link className="nav-link" to="/skills">
              Skills
            </Link>
          </li>
          <li className="link">
            <Link className="nav-link" to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
