import React, { useState } from "react";
import CardLarge from "../common/cardLarge";
import PopupDetails from "../common/popupDetails";
import triarc from "../../assets/images/dsk-triarc.png";
import reliant from "../../assets/images/dsk-reliant.png";
import github from "../../assets/images/github.png";
import fss from "../../assets/images/fss.png";
import envelopes from "../../assets/images/envelopes-desktop.png";
import sp from "../../assets/images/sp-home.png";

// SP images
import sp1 from "../../assets/images/sp-dealer.png"
import sp2 from "../../assets/images/sp-dealer-dash.png"
import sp3 from "../../assets/images/sp-dealer-demo-1.png"
import sp4 from "../../assets/images/sp-dealer-demo-2.png"
import sp5 from "../../assets/images/sp-dealer-demo-3.png"
import sp6 from "../../assets/images/sp-demo-1.png"
import sp7 from "../../assets/images/sp-demo-2.png"

// Envolpes
import envelopes1 from "../../assets/images/envelopes-desktop.png"
import envelopes2 from "../../assets/images/envelopes-summary.png"
import envelopes3 from "../../assets/images/envelopes-summary-2.png"

const Projects = () => {

  const [showSpPopup, setShowSpPopup] = useState(false);
  const [showReliantPopup, setShowReliantPopup] = useState(false);
  const [showEnvelopesPopup, setShowEnvelopesPopup] = useState(false);
  const [showTriarcPopup, setShowTriarcPopup] = useState(false);


  return (
    <section className="projects">
      <CardLarge
        img={sp}
        title="SmartPay Rentals"
        text="Affordable storage shed rent-to-own service."
        pop="Built with HTML, PHP, MySQL and React"
        action={() => setShowSpPopup(true)}
      />

      <PopupDetails
        display={showSpPopup}
        handleDisplay={setShowSpPopup}
        title='SmartPay Rentals'
        details='At SP I was responsible for updating the UI/UX for all interface portals, public and private. I maintained custom finance software and added additional features. Created an external POS interface for our affiliates to generate contracts upon successful payments. Worked on and enhanced a transportation portal for dealers and delivery drivers to track and map deliveries and pickups.'
        skills={['php', 'js', 'jq', 'react', 'sql', 'apache', 'linux', 'html', 'css', 'sass']}
        images={[sp1, sp2, sp3, sp4, sp5, sp6, sp7]}
      />

      <CardLarge
        img={reliant}
        title="Reliant Energy"
        text="Affordable electricity company based in Houston Texas."
        pop="Built with PHP, JS, MySQL, React and Adobe Analytics"
        action={() => setShowReliantPopup(true)}
      />

      <PopupDetails
        display={showReliantPopup}
        handleDisplay={setShowReliantPopup}
        title='Reliant/NRG'
        details='At NRG I led a special projects team that created and maintained various tools to improve NRG brands. One of those tools was an NPS dashboard used across all brands to view nps scores and feedback. I created a promotion code generator to prevent competitive espionage in regards to current promotions. I also worked with Adobe Analytics and used Adobe Experience Manager for A/B user experiences.'
        skills={['react', 'js', 'jq', 'php', 'sql', 'apache', 'linux', 'html', 'css', 'adobe']}
      />

      <CardLarge
        img={envelopes}
        title="Envelopes"
        text="A budgeting tool to help you keep track of your financial goals."
        pop="Built with React, PHP and MySQL"
        action={() => setShowEnvelopesPopup(true)}
      />

      <PopupDetails
        display={showEnvelopesPopup}
        handleDisplay={setShowEnvelopesPopup}
        title='Envelopes'
        link="http://envelopes.cash/"
        details='This is a finance tool I created and continue to use. It helps you keep track of your financial goals and provides summary data to stay on top of expenditures. Envetually I plan to expand this tools features and market it as a web app.'
        skills={['react', 'php', 'sql', 'apache', 'linux', 'html', 'sass']}
        images={[envelopes1, envelopes2, envelopes3]}
      />

      <CardLarge
        img={fss}
        title="Five Solas Studios"
        link="http://fivesolasstudios.com/"
        text="Media studio for Five Solas Church"
        pop="Built with React, PHP and MySQL"
      />

      <CardLarge
        img={triarc}
        title="Triarc Support Monitor"
        text="Custom dashboard for active monitoring."
        pop="Built with PHP, JS, and SQL "
        action={() => setShowTriarcPopup(true)}
      />

      <PopupDetails
        display={showTriarcPopup}
        handleDisplay={setShowTriarcPopup}
        title='Triarc Support Monitor'
        details="This was a dashboard I built for internal use at Triarc Solutions. I used a custom php backend to work with the client's Azure server."
        skills={['js', 'php',  'html']}
      />

      <CardLarge
        img={github}
        title="My Git Projects"
        link="https://github.com/levelup-enterprises"
        text="Head here to see some of my code!"
        pop="React, JS, jQuery and PHP"
      />
    </section>
  );
};

export default Projects;
