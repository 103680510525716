import React from "react";

const formTextarea = ({
  name,
  value,
  placeholder,
  onChange,
  rows = "3",
  hidden = ""
}) => {
  return (
    <div className="form-group">
      <textarea
        name={name}
        value={value}
        id={name}
        className="form-control"
        rows={rows}
        placeholder={placeholder}
        hidden={hidden}
        onChange={onChange}
      />
    </div>
  );
};

export default formTextarea;
