import React, { Component } from "react";
import ContactForm from "../common/contactForm";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: [
        { position: "angle-90", text: "What do you think?" },
        { position: "angle-90-2", text: "Want to know more?" },
        { position: "angle-180", text: "Have a question?" },
        { position: "angle-180-2", text: "Like what you see?" },
        { position: "angle-180-3", text: "Whats 5 + 7 = ?", size: "h3" },
        { position: "angle-180-4", text: "Ready to get started?" }
      ]
    };
  }

  render() {
    return (
      <ContactForm section={"questions"} questions={this.state.questions} />
    );
  }
}

export default Contact;
