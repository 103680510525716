import React from "react";

const EmailAlert = ({ sent }) => {
  return (
    <React.Fragment>
      {sent ? (
        <div className="email-fade success">
          <div className="container-fluid email-sent">
            <h1>Email Submitted!</h1>
          </div>
        </div>
      ) : (
        <div className="email-fade failed">
          <div className="container-fluid email-sent">
            <h1>Email Failed!</h1>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EmailAlert;
