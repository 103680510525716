import React from "react";

const NotFound = () => {
  return (
    <React.Fragment>
      <h1>404</h1>
      <h2>Not Found</h2>
    </React.Fragment>
  );
};

export default NotFound;
