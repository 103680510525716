import React, { useEffect, useState } from "react";

import html from "../../assets/images/icon-html.png";
import css from "../../assets/images/icon-css.png";
import php from "../../assets/images/icon-php.png";
import js from "../../assets/images/icon-js.png";
import jq from "../../assets/images/icon-jquery.png";
import sql from "../../assets/images/icon-mysql.png";
import react from "../../assets/images/icon-react.png";
import native from "../../assets/images/icon-native.png";
import apache from "../../assets/images/icon-apache.png";
import linux from "../../assets/images/icon-linux.png";
import adobe from "../../assets/images/icon-adobe.png";
import sass from "../../assets/images/sass.svg";
import Carousel from "./carousel";

const PopupDetails = ({ display, handleDisplay, title, skills, details, images, link }) => {

  const skillIcons = {
    'html': html,
    'css': css,
    'php': php,
    'js': js,
    'jq': jq,
    'sql': sql,
    'react': react,
    'native': native,
    'apache': apache,
    'linux': linux,
    'adobe': adobe,
    'sass': sass,
  }

  return (
    <div className={"popup" + (display ? ' show' : '')}>
      <div className="popup-content">
        <button className="btn close" onClick={() => handleDisplay(false)}>X</button>
        <h1>{title}</h1>
        <p className="details">{details}</p>
        {link && <a href={link} target="_blank">View Site</a>}
        <div className="skill-wrapper">
          {skills && skills.map((skill) => 
            <img className="skill-icon" src={skillIcons[skill]} alt={skill} key={skill}/>
          )}
        </div>
        {images && <Carousel images={images} />}
      </div>
    </div>
  );
};

export default PopupDetails;
