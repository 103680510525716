import React from "react";
import CardSkills from "../common/cardSkills";
import html from "../../assets/images/icon-html.png";
import css from "../../assets/images/icon-css.png";
import php from "../../assets/images/icon-php.png";
import js from "../../assets/images/icon-js.png";
import jq from "../../assets/images/icon-jquery.png";
import sql from "../../assets/images/icon-mysql.png";
import react from "../../assets/images/icon-react.png";
import native from "../../assets/images/icon-native.png";
import apache from "../../assets/images/icon-apache.png";
import linux from "../../assets/images/icon-linux.png";
import adobe from "../../assets/images/icon-adobe.png";
import sass from "../../assets/images/sass.svg";

const Skills = () => {
  return (
    <React.Fragment>
      <section className="light">
        <CardSkills img={react} title="React" text="Expert Status" />
        <CardSkills img={html} title="HTML" text="Expert Status" />
        <CardSkills img={css} title="CSS" text="Expert Status" />
        <CardSkills img={php} title="PHP" text="Expert Status" />
        <CardSkills img={js} title="JS" text="Expert Status" />
        <CardSkills img={jq} title="JQuery" text="Expert Status" />
        <CardSkills img={sql} title="MySQL" text="Expert Status" />
      </section>
      <section className="dark">
        <CardSkills img={sass} title="Sass" text="Pro Status" />
        <CardSkills img={apache} title="Apache" text="Pro Status" />
        <CardSkills img={linux} title="Linux" text="Pro Status" />
        <CardSkills img={adobe} title="Adobe Analytics" text="Pro Status" />
      </section>
    </React.Fragment>
  );
};

export default Skills;
