import React, { Component } from "react";
import emailjs from '@emailjs/browser';

import FormInput from "./formInput";
import OuterText from "./formOuterText";
import FormTextarea from "./formTextarea";
import Button from "./button";
import EmailAlert from "./alertEmail";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      response: "",
      equation: {},
      form: {
        fname: "",
        lname: "",
        email: "",
        message: "",
        confirm: "",
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    const plus = Math.round(Math.random() * 20);
    const minus = Math.round(Math.random() * 20);
    const total = plus + minus;

    this.setState({ equation: { total: total, plus: plus, minus: minus } });
  };

  handleChange = ({ currentTarget: input }) => {
    const form = { ...this.state.form };
    form[input.name] = input.value;
    this.setState({ form });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let form = this.state.form;

    if (form.confirm == this.state.equation.minus) {
      delete form.confirm;

      emailjs.send('service_allyxdv', 'template_6vobehx', form, {publicKey: 'BZHDnPa8yu29CDUFq'}).then(() => {
        this.setState({ alert: true, response: true, errors: false });
      }, (error) => {
        this.setState({ alert: true, response: false });
        console.error(error)
      })

      // Reset
      setTimeout(() => {
        this.setState({
          alert: false,
          form: {
            fname: "",
            lname: "",
            email: "",
            message: "",
            confirm: "",
          },
        });
      }, 2000);
    } else {
      this.setState({ errors: true });
    }
  };

  createQuestions = () => {
    const { questions } = this.props;
    if (questions !== undefined) {
      let outerText = [];
      for (var i = 0; i < questions.length; i++) {
        outerText.push(
          <OuterText
            position={questions[i].position}
            text={questions[i].text}
            size={questions[i].size === undefined ? questions[i].size : ""}
            key={i}
          />
        );
      }
      return outerText;
    }
  };

  render() {
    const { section } = this.props;
    const { fname, lname, email, message, confirm } = this.state.form;
    const { total, plus } = this.state.equation;
    return (
      <section className={"contact " + section}>
        <div className="container">
          <form id="contact" onSubmit={this.handleSubmit}>
            {this.createQuestions()}
            <h2>Contact Us</h2>
            <div className="flex-container">
              <FormInput
                name="fname"
                placeholder="First Name"
                inline="inline"
                value={fname}
                onChange={this.handleChange}
              />
              <FormInput
                name="lname"
                placeholder="Last Name"
                inline="inline"
                value={lname}
                onChange={this.handleChange}
              />
            </div>
            <FormInput
              name="email"
              type="email"
              placeholder="Your email"
              value={email}
              onChange={this.handleChange}
            />
            <FormInput name="subject" placeholder="Subject" hidden="hidden" />
            <FormTextarea
              name="message"
              placeholder="What can we help you with?"
              value={message}
              onChange={this.handleChange}
            />
            <div className="equation">
              <h3>{plus} + </h3>
              <input
                name="confirm"
                type="number"
                value={confirm}
                onChange={this.handleChange}
              />
              <h3>= {total}</h3>
            </div>
            {this.state.errors && <p>Please answer math challenge</p>}
            <Button type="submit" text="Send us an email!" />
          </form>
        </div>
        {this.state.alert && <EmailAlert sent={this.state.response} />}
      </section>
    );
  }
}

export default ContactForm;
