import React from "react";
import { Link } from "react-router-dom";
import Card from "../common/card";
import ContactForm from "../common/contactForm";
import team from "../../assets/images/team.png";
import imgCog from "../../assets/images/cog.png";
import imgSeo from "../../assets/images/seo.png";
import imgScreen from "../../assets/images/screen.png";

const Home = () => {
  return (
    <React.Fragment>
      <section className="about">
        <div className="about-img">
          <img src={team} alt="Team" />
        </div>
        <div className="about-txt">
          <h1>hi there!</h1>
          <p>
            I'm a web developer who wants to make the web a better place for
            <span> you</span>!
          </p>
        </div>
      </section>

      <section className="works">
        <Card
          img={imgCog}
          title="Build"
          text="Have a small business? Need some work done on an existing site? Or are you just wanting to be"
          bold="trendy"
          pop="I can build and manage a solution that works for you!"
        />

        <Card
          img={imgSeo}
          title="SEO"
          text="Not getting enough traffic? Want to be at the top of the "
          bold="first page"
          textEnd=" when searched?"
          pop="Let me help you improve your SEO to get you noticed!"
        />

        <Card
          img={imgScreen}
          title="IT"
          text="Need "
          bold="IT"
          textEnd=" work done on your existing site?"
          pop="Whether its broken code, email issues, or integration problems I can find solutions to get you back up and running, so you can do what you do best."
        />
      </section>

      <section className="projects">
        <Link className="prj-link" to="/projects" title="Projects">
          <div className="prj-txt">
            <h2>Head here to see what I've created for others.</h2>
          </div>
        </Link>
      </section>
      <ContactForm />
    </React.Fragment>
  );
};

export default Home;
